<template>
  <div class="tile is-ancestor is-parent full-quote-page">
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <div class="columns is-gapless">
              <div class="column">
                <p class="title">Part</p>
                <subtitle-totals :labels="['Part Total', 'Grand Total']"
                  :totals="[partTotal, innerValue.totalExGst]"
                  :shows="[true, true]" />
              </div>
              <div class="column">
                <div class="control is-pulled-right">
                  <div class="field-body">
                    <div class="field py-0">
                      <div v-if="audanetLock && !$showSpinnerRequestCount">
                        <audanet-lock-notification />
                      </div>
                    </div>
                    <div v-if="partsCheckSettingExists"
                      class="field mr-2">
                      <div v-if="!innerValue.readOnly || items.length > 0 && !readOnlyView"
                        class="dropdown is-right tooltip"
                        :class="{ 'is-active': partsCheckDropdownActive }"
                        @mouseleave="partsCheckDropdownActive = false"
                        data-tooltip="PartsCheck">
                        <div class="dropdown-trigger">
                          <button class="button is-info"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu-partscheck"
                            @click="togglePartsCheckMenu">
                            <span>PartsCheck</span>
                            <span class="icon is-small">
                              <i class="mdi mdi-chevron-down mdi-24px"
                                aria-hidden="true" />
                            </span>
                          </button>
                        </div>

                        <div class="dropdown-menu"
                          id="dropdown-partscheck"
                          role="menu">
                          <div class="dropdown-content">
                            <a href="#"
                              class="dropdown-item dropdown-item-has-icon"
                              @click="getPartsPrices">
                              <span class="icon is-small has-text-primary">
                                <i class="mdi mdi-download mdi-18px"
                                  aria-hidden="true" />
                              </span>
                              <span class="dropdown-item-text">Download Prices</span>
                            </a>
                            <a v-if="items.length > 0"
                              href="#"
                              class="dropdown-item dropdown-item-has-icon"
                              @click="showPartsCheckModal">
                              <span class="icon is-small has-text-primary">
                                <i class="mdi mdi-upload mdi-18px"
                                  aria-hidden="true" />
                              </span>
                              <span class="dropdown-item-text">Submit Parts &amp; Images</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="buttons">
                        <button v-if="hasPartsForEnquiry"
                          class="button is-info tooltip"
                          data-tooltip="Parts Enquiry"
                          @click="showPartsEnquiry()">
                          <span class="icon">
                            <i class="mdi mdi-comment-question" />
                          </span>
                          <span>Parts Enquiry</span>
                        </button>
                        <button v-if="value.subQuoteNo > 0 && !innerValue.readOnly && !readOnlyView && !audanetLock"
                          class="button is-success tooltip is-tooltip-topright"
                          data-tooltip="Adjust Main Quote Items"
                          @click="toggleAvailableItemsMain()"
                          :disabled="innerValue.readOnly || readOnlyView">
                          <span class="icon">
                            <i class="mdi"
                              :class="{ 'mdi-adjust' : !isAvailableItemsMainActive, 'mdi-close' : isAvailableItemsMainActive }" />
                          </span>
                          <span>{{ isAvailableItemsMainActive ? 'Done' : 'Adjust' }}</span>
                        </button>
                        <button class="button is-primary tooltip is-tooltip-topright"
                          data-tooltip="Toggle Deleted Parts Visibility"
                          @click="()=>{displayDeleted = !displayDeleted}">
                          <span class="icon">
                            <i class="mdi"
                              :class="{ 'mdi-eye-off' : displayDeleted, 'mdi-eye' : !displayDeleted }" />
                          </span>
                          <span>{{ displayDeleted ? 'Hide' : 'Show' }}</span>
                        </button>
                        <button v-if="!innerValue.readOnly && !readOnlyView && !audanetLock"
                          class="button is-primary tooltip is-tooltip-topright"
                          data-tooltip="Add new part"
                          @click="toggleAvailableParts()"
                          :disabled="innerValue.readOnly || readOnlyView">
                          <span class="icon">
                            <i class="mdi"
                              :class="{ 'mdi-plus' : !isAvailableItemsActive, 'mdi-minus' : isAvailableItemsActive }" />
                          </span>
                          <span>{{ isAvailableItemsActive ? 'Close' : 'Add' }}</span>
                        </button>
                        <button v-if="!innerValue.readOnly && !readOnlyView && !audanetLock"
                          class="button is-primary tooltip is-tooltip-topright"
                          data-tooltip="Add blank item"
                          @click="addBlankItem"
                          :disabled="innerValue.readOnly || readOnlyView">
                          <span class="icon">
                            <i class="mdi mdi-shape-square-plus" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <available-parts v-if="isAvailableItemsActive"
              v-model="innerValue.parts"
              :quote-id="value.quoteId"
              :quote-no="value.quoteNo"
              :sub-quote-no="value.subQuoteNo"
              :sub-quote-nos="value.subQuoteNos"
              :is-audanet="value.audatex !== null"
              :next-line-number="nextLineNumber"
              :vehicle-id="vehicle ? vehicle.id : ''"
              @item-added="onItemAdded" />
            <available-parts-main v-if="isAvailableItemsMainActive"
              v-model="innerValue.parts"
              :quote-id="value.quoteId"
              :quote-no="value.quoteNo"
              :sub-quote-no="value.subQuoteNo"
              :next-line-number="nextLineNumber"
              @item-added="onItemAdded" />
            <div v-if="isAvailableItemsActive || isAvailableItemsMainActive"
              class="is-divider" />

            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              :draggable="true"
              :show-pagination="false"
              @pageChanged="onPageChange"
              @sort="sort">
              <draggable v-model="innerValue.parts"
                :tag="'tbody'"
                v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
                @change="itemMoved"
                @end="endMove"
                handle=".item-drag-handle">
                <tr v-for="(item, index) in activeItems"
                  :key="item.quoteItemId"
                  :class="[defaultRowColourClass(item), rowDisplayStatus(item, getItemAssessments(item))]"
                  @click="highlightSelected(index, $event)"
                  :data-itemid="item.quoteItemId">
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle has-text-right linenumber-input">
                    <vue-numeric v-if="!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.lineNumber"
                      :precision="0"
                      separator="" />
                    <span v-else>{{ item.lineNumber }}</span>
                  </td>
                  <td class="has-vertical-middle"
                    :class="{'itemno-input': !item.deleteOtherItem}">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item No."
                      :disabled="item.deleteOtherItem"
                      v-model="item.itemNo">
                    <span v-else>{{ item.itemNo }}</span>
                  </td>
                  <td class="has-vertical-middle"
                    :class="[defaultCellColourClass(item,'itemDesc'), !item.deleteOtherItem ? 'itemdesc-input' : '']"
                    :content="getTooltipCommunicationHistoryForField(item, 'itemDesc')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemDesc')">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="input"
                      :class="[defaultRowColourClass(item), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
                      placeholder="Item Description"
                      v-focus-inserted="{ focus: $userInfo.scrollItemPart }"
                      :disabled="item.deleteOtherItem"
                      v-model="item.itemDesc">
                    <span v-else>{{ item.itemDesc }}</span>
                    <div v-if="item.itemComment"
                      class="help is-primary">
                      <span class="has-text-weight-bold">Comment: </span>
                      <span>{{ item.itemComment }}</span>
                    </div>
                  </td>
                  <td class="has-vertical-middle has-text-centered">
                    <div v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                      class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                      <input v-model="item.reportOnly"
                        type="checkbox"
                        :disabled="item.deleteOtherItem">
                      <div class="state p-info">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                    <span v-else
                      class="icon has-text-info">
                      <i class="mdi mdi-24px"
                        :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
                    </span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">
                    <div v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="select">
                      <select v-model="item.mark"
                        :class="defaultRowColourClass(item)"
                        :disabled="item.deleteOtherItem"
                        @change="onPartConditionChange(index, item)">
                        <option v-for="(typeObject, type) in partTypes"
                          :value="typeObject.value"
                          :key="typeObject.value"
                          :title="typeObject.description">{{ type | formatPartCondition }}</option>
                      </select>
                    </div>
                    <span v-else>{{ item.mark | formatPartType }}</span>
                  </td>
                  <td :class="['has-vertical-middle has-text-right qty-input',defaultCellColourClass(item,'itemQuantity')]"
                    :content="getTooltipCommunicationHistoryForField(item, 'itemQuantity')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemQuantity')">
                    <vue-numeric v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.itemQuantity"
                      :minus="innerValue.subQuoteNo !== 0"
                      :precision="0"
                      @input="updateValue(index, item)" />
                    <span v-else>{{ item.itemQuantity }}</span>
                  </td>
                  <td :class="['has-vertical-middle has-text-right money-input',defaultCellColourClass(item,'value')]"
                    :content="getTooltipCommunicationHistoryForField(item, 'value')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'value')">
                    <vue-numeric v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.value"
                      :minus="true"
                      :precision="2"
                      @input="updateMarkup(index, item)" />
                    <span v-else>{{ item.value | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-right"
                    :class="{'money-input': !item.deleteOtherItem}">
                    <vue-numeric v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.markupValue"
                      :minus="true"
                      :precision="2"
                      :disabled="item.deleteOtherItem"
                      @input="updateTotal()" />
                    <span v-else>{{ item.markupValue | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-right">
                    <div>{{ item.itemQuantity * item.markupValue | formatNumber($userInfo.locale) }}</div>
                  </td>
                  <td :class="['has-vertical-middle partno-input',defaultCellColourClass(item,'partNo')]"
                    :content="getTooltipCommunicationHistoryForField(item, 'partNo')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'partNo')">
                    <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input is-uppercase"
                      :class="defaultRowColourClass(item)"
                      placeholder="Part No."
                      :disabled="item.deleteOtherItem"
                      v-model="item.partNo">
                    <span v-else>{{ item.partNo }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">{{ item.quantityOrdered }}</td>
                  <td class="has-vertical-middle has-text-centered is-content-width">{{ item.quantityReceived }}</td>
                  <td class="has-vertical-middle has-text-centered is-content-width">{{ item.quantityReturned }}</td>
                  <td class="has-vertical-middle has-text-right"
                    :class="{'money-input': !item.deleteOtherItem}">
                    <vue-numeric v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input has-text-right"
                      :class="defaultRowColourClass(item)"
                      v-model.number="item.buyPrice"
                      :min="0"
                      :disabled="item.deleteOtherItem || (item.quantityReceived>0 && !$userInfo.isSupportUser)"
                      :precision="2" />
                    <span v-else>{{ item.buyPrice | formatNumber($userInfo.locale) }}</span>
                  </td>
                  <td v-show="$userInfo.isSupportUser"
                    class="has-vertical-middle itemstatus-input">
                    <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
                      class="input is-capitalized"
                      v-model="item.itemStatus">
                    <span v-else>{{ item.itemStatus }}</span>
                  </td>
                  <td class="has-vertical-middle has-text-centered is-content-width">
                    <div class="icon-container  items-center">
                      <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
                        :item="item"
                        :item-assessments="getItemAssessments(item)"
                        :next-assessment-number="nextSupplemetryNo"
                        :quoting-method="innerValue.quotingMethod"
                        :icon-size="4"
                        class="icon" />
                      <button v-if="!audanetLock && !isItemRemoved(item, getItemAssessments(item)) && (item.isDeleted === false || item.deleted === false)"
                        class="button is-danger is-small is-inverted"
                        @click="deleteItem(item, index)"
                        :disabled="innerValue.readOnly || readOnlyView || isItemRemoved(item, getItemAssessments(item))">
                        <span class="icon is-medium">
                          <i class="mdi mdi-delete mdi-24px" />
                        </span>
                      </button>
                      <button class="button is-primary is-small is-inverted item-drag-handle pr-0 pl-1"
                        :disabled="innerValue.readOnly || readOnlyView">
                        <span class="icon is-medium">
                          <i class="mdi mdi-drag-vertical mdi-24px" />
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </draggable>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
              <template slot="footer">
                <tr>
                  <th class="has-text-right has-text-success"
                    :colspan="totalColSpan">Totals</th>
                  <th class="has-text-right has-text-success"
                    :class="[ innerValue.partTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ innerValue.partTotal | formatCurrency($userInfo.locale) }}</th>
                  <th colspan="100%" />
                </tr>
              </template>
            </bulma-table>
          </div>
        </div>
      </article>
    </div>
    <parts-check-export-modal v-if="isPartsCheckExportModalActive"
      :active.sync="isPartsCheckExportModalActive"
      :value="innerValue"
      @sent="reload" />
    <parts-enquiry-modal v-if="isPartsEnquiryModalActive"
      :active.sync="isPartsEnquiryModalActive"
      :value="items"
      :quote="innerValue"
      @close="closeModal()"
      @showReport="closeModal()" />
    <totals-floating-widget :active.sync="isFloatingWidgetActive"
      title="Part Totals"
      :totals="partTotal"
      :total-ex-gst="innerValue.totalExGst" />
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { PartColumns, PartDeletedColumns } from './columns'
import { PartTypes, ItemCategoryTypes, QuoteItemStatusTypes, EventHubTypes, QuoteItemRevTypes } from '@/enums'
// import _orderBy from 'lodash/orderBy'
// import _isEmpty from 'lodash/isEmpty'
import VueNumeric from '@/components/VueNumeric'
import QuoteFiltersMixins from './QuoteFiltersMixins'
import QuoteService from './QuoteService'
import { TotalsFloatingWidget, AvailableParts, AvailablePartsMain, PartsEnquiryModal, PartsCheckExportModal, SupplementaryIcon } from './components'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import draggable from 'vuedraggable'
import { FocusInserted } from '@/components/directives'
import { QuoteAssessmentMixin, QuoteItemDifferenceMixin, QuoteItemValidationMixin, QuotePartMixin } from './mixins'
import Guid from '@/components/Guid'
import { QuoteItemModel } from '@/classes/viewmodels'
import { PartsCheckService, AudaNetService } from './services'
import SubtitleTotals from '@/components/SubtitleTotals'

export default {
  name: 'QuotePart',
  directives: { FocusInserted },
  components: {
    BulmaTable,
    VueNumeric,
    AvailableParts,
    AvailablePartsMain,
    TotalsFloatingWidget,
    SupplementaryIcon,
    draggable,
    SubtitleTotals,
    PartsEnquiryModal,
    PartsCheckExportModal
  },
  filters: {
    formatPartType(value) {
      // for (var key in PartTypes) {
      //   console.log(PartTypes[key], key)
      // }
      const index = Object.values(PartTypes).findIndex((item) => item.value === value)
      return Object.keys(PartTypes)[index]
    }
  },
  mixins: [QuoteItemDifferenceMixin, NumberFiltersMixin, QuoteFiltersMixins, QuoteItemValidationMixin, QuotePartMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    vehicle: null,
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayDeleted: false,
      selectedRow: null,
      // totalRows: 0,
      isTableLoading: false,
      filter: {
        sortColumn: PartColumns[1].name,
        sortOrder: PartColumns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      },
      filterDeleted: {
        sortColumn: PartColumns[1].name,
        sortOrder: PartColumns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      },
      // items: null,
      innerValue: null,
      isAvailableItemsActive: false,
      isAvailableItemsMainActive: false,
      isFloatingWidgetActive: true,
      isPartsEnquiryModalActive: false,
      partsCheckDropdownActive: false,
      // partsCheckSettingExists: null,
      isPartsCheckExportModalActive: false
    }
  },
  computed: {
    columns() {
      const c = PartColumns
      c[1].isVisible = this.$userInfo.isSupportUser
      c[c.length - 2].isVisible = this.$userInfo.isSupportUser
      return PartColumns
    },
    deletedColumns() {
      return PartDeletedColumns
    },
    partTypes() {
      return PartTypes
    },
    activeItems() {
      return this.items.filter((i) => this.isItemDeleted(i, this.getItemAssessments(i)) === false)
    },
    activeDeletedItems() {
      return this.items //.filter((i) => !i.deleted && i.deleteOtherItem)
    },
    totalRows() {
      return this.activeItems.length
    },
    totalRowsDeleted() {
      return this.activeDeletedItems.length
    },
    hasPartsForEnquiry() {
      return this.items.some((i) => !i.isNew && !i.deleted && !i.reportOnly)
    },
    items() {
      if (this.innerValue) {
        return this.innerValue.parts //.filter((i) => !i.deleted) // _orderBy(this.innerValue.parts.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
      }
    },
    totalColSpan() {
      return this.columns.length - (this.$userInfo.isSupportUser ? 9 : 10)
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },

    itemsAssessments() {
      return this.getLabourItemsAssessments()
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.value.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    } //,
    // showDownloadPrices() {
    //   if (this.innerValue.audatex !== null) {
    //     if (
    //       this.innerValue.audatex.businessStatusText == 'Authorised' ||
    //       this.innerValue.audatex.businessStatusText == 'Supp Authorised' ||
    //       this.innerValue.audatex.businessStatusText == 'Pending Auth' ||
    //       this.innerValue.audatex.businessStatusText == 'Supp Pending Auth'
    //     )
    //       return false
    //     else {
    //       const audaNetTask = this.getAudaNetTask()
    //       if (audaNetTask !== null)
    //         return audaNetTask.businessStatusText == 'Authorised' ||
    //           audaNetTask.businessStatusText == 'Supp Authorised' ||
    //           audaNetTask.businessStatusText == 'Pending Auth' ||
    //           audaNetTask.businessStatusText == 'Supp Pending Auth'
    //           ? false
    //           : true
    //       else return false
    //     }
    //   }
    //   else
    //    return this.innerValue.readOnly ? false : true
    // }
    // partTotal() {
    //   return this.calculateItemCategoryTotal(this.value.quotingMethod, this.items, ItemCategoryTypes.PART)
    // }
    // items() {
    //   return _orderBy(this.innerValue.parts.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    // }
  },
  watch: {
    // value: {
    //   handler: function(newVal, oldVal) {
    //     this.innerValue = newVal
    //     this.filter.sortColumn = ''
    //     this.filter.sortOrder = ''
    //     // this.items = _orderBy(this.innerValue.parts.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    //   },
    //   deep: true
    // },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.getExternalSetting('partscheck'))
  },
  created() {
    this.innerValue = this.value
    console.log(this.innerValue.parts)
    // this.items = _orderBy(this.innerValue.parts.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    // this.getQuoteItems()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = this.value
    })
    this.$eventHub.$on(EventHubTypes.EntityReloaded, () => {
      this.innerValue = this.value
    })
    this.$v.entity.parts.$touch()
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReloaded)
  },
  methods: {
    highlightSelected(index, event) {
      // this.selectedRow = index
    },
    onPageChange(pageIndex) {},
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      // this.items = _orderBy(this.innerValue.parts.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
    },
    sideTagClass(side) {
      let tagClass = ''
      switch (side) {
        case 'O':
          tagClass = 'is-light'
          break
        case 'R':
          tagClass = 'is-primary'
          break
        case 'L':
          tagClass = 'is-info'
          break
      }
      return tagClass
    },
    toggleAvailableParts() {
      this.isAvailableItemsMainActive = false
      this.isAvailableItemsActive = !this.isAvailableItemsActive
    },
    toggleAvailableItemsMain() {
      this.isAvailableItemsActive = false
      this.isAvailableItemsMainActive = !this.isAvailableItemsMainActive
    },
    updateTotal(index, item) {
      this.isFloatingWidgetActive = true
    },
    deleteItem(item, index) {
      let originalItemStatus = item.itemStatus
      this.deleteLinkedItem(item)
      if (item.isNew) {
        const itemIndex = this.innerValue.parts
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.parts.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.updateTotal()
      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.parts.splice(this.innerValue.parts.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    restoreItem(item, index) {
      item.deleted = false
      item.isDeleted = false
      //TODO: must set item status to original item status
      this.updateTotal()
    },
    deleteLinkedItem(item) {
      if (this.isNtar && Guid.validGuid(item.linkedItemId)) {
        const linkedItem = this.innerValue.labours.find((i) => i.quoteItemId === item.linkedItemId && i.isLoadItem && !i.deleted)
        console.log(linkedItem)
        if (linkedItem) {
          this.deletePaintLoadingItem(linkedItem)
        }
      }
    },
    onItemAdded(newItem) {
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.parts.splice(this.innerValue.parts.length, 1, newItem)
      // this.items = _orderBy(this.innerValue.parts.filter(i => !i.deleted), [this.filter.sortColumn], [this.filter.sortOrder])
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
      // this.items = this.value.parts.filter(i => !i.deleted)
      // reset sort
    },
    itemMoved(event) {
      let sortNo = Math.min(...this.innerValue.parts.map((i) => i.sortNo))
      this.innerValue.parts.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    endMove(event) {
      // console.log(event)
    },
    async getQuoteItems() {
      if (this.innerValue.subQuoteNo === 0) {
        this.partsMain = await QuoteService.getQuoteItems(this.innerValue.quoteNo, 0, [ItemCategoryTypes.PART])
      } else {
        const vm = this
        this.innerValue.subQuoteNos.forEach(async function (q) {
          if (q.key !== 0) {
            const items = await QuoteService.getQuoteItems(vm.innerValue.quoteNo, q.key, [ItemCategoryTypes.PART])
            const prop = `parts${q.key}`
            vm.partsSupp[prop] = items
          }
        })
      }
    },
    async getQuoteMainItems(quoteNo, subQuoteNo, type) {
      this.partsMain = await QuoteService.getQuoteItems(quoteNo, subQuoteNo, type)
    },
    async getDefaultPaintItem(itemNo) {
      const filter = {
        countryId: Guid.empty(),
        companyId: this.value.companyId,
        query: itemNo,
        butterflyCode: '',
        scheduleId: this.vehicle.scheduleId || '',
        bodyId: this.vehicle.bodyId || '',
        bodySize: '',
        paintGroup: this.vehicle.paintGroup || '',
        variantId: 0,
        nvic: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 1
      }
      const paintItem = await QuoteService.getAvailableLabourItems(filter, this.value.labourType.toLowerCase())
      return paintItem
    },
    async getQuoteSuppItems(quoteNo, subQuoteNo, type) {
      const items = await QuoteService.getQuoteItems(quoteNo, subQuoteNo, type)
      const prop = `parts${subQuoteNo}`
      this.partsSupp[prop] = items
    },
    showPartsEnquiry() {
      this.isPartsEnquiryModalActive = true
    },
    closeModal() {
      this.isPartsEnquiryModalActive = false
    },
    addBlankItem() {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', '', ItemCategoryTypes.PART)
      newItem.lineNumber = !this.isAudanet ? this.nextLineNumber : 0
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.itemQuantity = 1
      newItem.value = 0
      newItem.mark = PartTypes.NEW.value
      newItem.markupPercent = 0
      newItem.markupValue = 0
      newItem.buyPrice = 0
      newItem.partNo = ''
      newItem.side = ''
      newItem.sortNo = this.innerValue.parts.length ? Math.max(...this.innerValue.parts.map((i) => i.sortNo)) + 1 : 1
      if (this.isAudanet) {
        newItem.rev = QuoteItemRevTypes.NonAudaNetPart
      }
      this.innerValue.parts.splice(this.innerValue.parts.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })
    },
    getCustomMarkupPercent(item) {
      const percentage = roundAwayFromZero(100 * ((item.markupValue - item.value) / item.value))
      // const markupValue = roundAwayFromZero(item.value * (1 + percentage / 100))
      // if (markupValue !== item.markupValue && percentate) {
      //   this.$snackbar.open({
      //     message: `Suggested amount for this item ${markupValue}`,
      //     type: 'is-warning',
      //     position: 'is-bottom',
      //     actionText: 'Update',
      //     onAction: () => {
      //       item.markupValue = markupValue
      //       this.updateTotal()
      //     }
      //   })
      // }
      return percentage
    },
    getCustomMarkupValue(item) {
      const percentage = roundAwayFromZero(100 * ((item.markupValue - item.value) / item.value))
      const markupValue = roundAwayFromZero(item.value * (1 + percentage / 100))
      // if (markupValue !== item.markupValue && percentate) {
      //   this.$snackbar.open({
      //     message: `Suggested amount for this item ${markupValue}`,
      //     type: 'is-warning',
      //     position: 'is-bottom',
      //     actionText: 'Update',
      //     onAction: () => {
      //       item.markupValue = markupValue
      //       this.updateTotal()
      //     }
      //   })
      // }
      return markupValue
    },
    updateMarkupValue(item) {
      const percentage = roundAwayFromZero(100 * ((item.markupValue - item.value) / item.value))
      const markupValue = roundAwayFromZero(item.value * (1 + percentage / 100))
      // if (markupValue !== item.markupValue && percentate) {
      //   this.$snackbar.open({
      //     message: `Suggested amount for this item ${markupValue}`,
      //     type: 'is-warning',
      //     position: 'is-bottom',
      //     actionText: 'Update',
      //     onAction: () => {
      //       item.markupValue = markupValue
      //       this.updateTotal()
      //     }
      //   })
      // }
      item.markupValue = markupValue
      this.updateTotal()
    },
    togglePartsCheckMenu() {
      this.partsCheckDropdownActive = !this.partsCheckDropdownActive
    },
    // async externalSettingExists(settingName) {
    //   this.partsCheckSettingExists = await CompanyService.externalSettingExists(settingName)
    // },
    async showPartsCheckModal() {
      if (!this.snapshotDiff) {
        this.isPartsCheckExportModalActive = true
      } else {
        this.showQuoteChangedToast()
      }
      // if (!this.snapshotDiff) {
      //   this.$showSpinner('Exporting...')
      //   const result = await PartsCheckService.exportParts(this.innerValue.quoteId)
      //   console.log(result)
      //   this.$hideSpinner()
      //   this.$eventHub.$emit(EventHubTypes.EntityReload)
      //   this.showSuccessToast('Parts exported to PartsCheck')
      // } else {
      //    this.showQuoteChangedToast()
      // }
    },
    reload() {
      this.$eventHub.$emit(EventHubTypes.EntityReload)
      this.showSuccessToast('Parts submitted to PartsCheck')
    },

    async getAudaNetTask() {
      await AudaNetService.getAudaNetTask(this.innerValue.quoteId)
    },

    async getPartsPrices() {
      if (!this.snapshotDiff) {
        this.$showSpinner('Fetching prices...')
        try {
          const countryCode = this.$company.info.countryCode
          const priceUpdate = await PartsCheckService.getPartsPrices(this.innerValue.quoteId)
          // console.log('getPartsPrices', priceUpdate)
          const vm = this
          if (priceUpdate.parts.length > 0) {
            let nextLineNumber = this.nextLineNumber
            priceUpdate.parts.forEach(function (partsCheckPart) {
              // console.log('getPartsPrices', partsCheckPart)
              let part
              if (partsCheckPart.isNew) {
                part = new QuoteItemModel(vm.innerValue.quoteId, partsCheckPart.itemNo, partsCheckPart.itemDesc, ItemCategoryTypes.PART)
                part.sortNo = vm.innerValue.parts.length ? Math.max(...vm.innerValue.parts.map((i) => i.sortNo)) + 1 : 1
                if (vm.innerValue.isAudaNet) {
                  part.lineNumber = 0
                } else {
                  part.lineNumber = nextLineNumber
                }
                if (vm.isAudanet) {
                  part.rev = QuoteItemRevTypes.NonAudaNetPart
                }
              } else {
                part = vm.innerValue.parts.find((i) => i.quoteItemId === partsCheckPart.quoteItemId)
                part.itemStatus = 'C'
              }

              if (countryCode !== 'MY' || (countryCode === 'MY' && partsCheckPart.isNew)) {
                part.value = partsCheckPart.unitPrice
                part.markupPercent = partsCheckPart.markupPercent
                part.markupValue = partsCheckPart.markupPrice
                part.mark = partsCheckPart.mark
              }

              part.itemQuantity = partsCheckPart.quantity
              part.buyPrice = partsCheckPart.buyPrice
              part.partNo = partsCheckPart.partNo
              part.side = partsCheckPart.side
              part.extLineId = partsCheckPart.partsCheckId
              part.partStatus = 'Imp'
              if (part.isNew) {
                if (countryCode === 'MY') {
                  part.itemComment = 'Part added by supplier'
                  part.reportOnly = true
                } else {
                  part.itemComment = partsCheckPart.itemComment
                  part.reportOnly = partsCheckPart.reportOnly
                }
                vm.innerValue.parts.splice(vm.innerValue.parts.length, 1, part)
                if (!vm.innerValue.isAudaNet) {
                  vm.innerValue.lines = vm.nextLineNumber + 1
                  nextLineNumber++
                }
              }
            })
            this.showSuccessToast('PartsCheck price updates downloaded', 5000)
          } else {
            this.$toast.open({
              message: 'There are no PartsCheck price updates',
              type: 'is-warning',
              queue: true,
              duration: 5000
            })
            this.$eventHub.$emit(EventHubTypes.EntityReload)
          }
        } catch {
        } finally {
          this.$hideSpinner()
        }
      } else {
        this.showQuoteChangedToast()
      }
    },
    sortDeleted() {},
    onPageChangeDeleted() {},
    deleteDeletedItem(item, index) {},
    getLabourItemsAssessments() {
      return this.getItemsAssessments(this.items, this.innerValue.quoteAssessments)
    },
    getItemAssessments(item) {
      const itemAssessments = this.itemsAssessments.find((i) => i.quoteItemId === item.quoteItemId)
      if (itemAssessments) {
        return itemAssessments.assessments
      }
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.linenumber-input {
  width: 60px;
}
.itemno-input {
  max-width: 2.5em;
}
.itemdesc-input {
  min-width: 20em;
}
.money-input {
  max-width: 4em;
}
.qty-input {
  max-width: 1.8em;
}
.partno-input {
  max-width: 6.5em;
}
.itemstatus-input {
  width: 50px;
}
a.dropdown-item-has-icon {
  display: flex;
  align-items: center;
}
span.dropdown-item-text {
  padding-left: 5px;
}
</style>
